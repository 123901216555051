@import '../_var.less';

@atom: DataTable;

.@{atom} {

    .Loader {
        display: none;
    }
    &__noData {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        justify-content: center;
        gap: .5rem;
        min-height: 22rem;
        img {
            width: 5rem;
            opacity: 0.75;
        }
        span {
            font-size: 1.3em;
            line-height: 1.3rem;
        }
    }

    .InputSearch--noResize {
        @dim-input-search: 130px;
        width: @dim-input-search;
        min-width: @dim-input-search;
    }

    &.@{atom}--loading {
        .Loader ~ * {
            display: none;
        }
        .Loader {
            display: flex;
        }
    }

    .@{atom}__upperCtn {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: space-between;

        .@{atom}__filterCtn {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;

            .InputDateRange {
                width: 170px;
                border: solid 1px @color-grey;
                cursor: pointer;
                border-radius: 5px;
                font-weight: bold;
                background: url(../../../public/ressources/img/calendar.svg) @color-white no-repeat scroll 10px center;
                color: @color-dark-grey;
                padding-left: 38px;

                width: 200px;
                border: solid 2px fade(@color-primary, 70%);
                color: black;
                border-radius: 3px;
                font-weight: 400;
                font-size: 1em;
            }
        }
        .@{atom}__actionCtn {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;

            .IconButton { margin-right: @dim-xs; }
            .Button { margin: 0; }
        }
    }

    .dataTables_wrapper {
        display: flex;
        flex-flow: row wrap;
        align-items: center;

        .dataTables_info {
            order: -1;
            font-family: @font-primary;
            font-size: @font-md + 0.2em;
            padding-top: 0;
            margin: 0 @dim-sm @dim-sm 0;
            font-weight: 600;
        }

        .dataTables_length {
            padding-top: 0;
            margin-bottom: @dim-sm;

            label {
                font-family: @font-primary;
                font-size: @font-xs;
                font-weight: 400;
                color: @color-main-text;
            }

            select {
                .mixin-input-search-small-style();
                margin: 0 @dim-xs;
            }
        }

        // Hide unused components
        .dataTables_filter,
        .select-info {
            display: none;
        }

        #atom-data-table_paginate {
            position: sticky;
            bottom: 0;
            background: linear-gradient(0deg,hsla(0,0%,100%,.9),hsla(0,0%,100%,.6) 65%,hsla(0,0%,100%,0));
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: @dim-md 0 @dim-sm 0;
            margin: 0;
            font-size: @font-xs;

            .paginate_button {
                @dim-pagination-btn: 32px;

                display: inline-flex;
                justify-content: center;
                align-items: center;
                width: @dim-pagination-btn;
                height: @dim-pagination-btn;
                background: none;
                border: none;
                border-radius: 50%;
                font-weight: 600;
                color: @color-main-text !important;
                transition: all .1s ease-out;
                margin: 0 3px;
                padding: 0;

                &.current {
                    background: @color-primary;
                    color: @color-white !important;
                    cursor: inherit;
                }

                &:hover:not(.current):not(.next):not(.previous) {
                    background: @color-grey;
                    color: @color-black !important;
                }

                &:focus {
                    box-shadow: none;
                }

                &.next,
                &.previous {
                    /* Hide the text. */
                    text-indent: 200%;
                    white-space: nowrap;
                    overflow: hidden;
                    background: url("../../../public/ressources/img/expand_more.svg") no-repeat right;

                    &.disabled {
                        opacity: .4;
                    }
                }

                &.next {
                    transform: rotate(-90deg) translateX(2px);
                    margin-left: @dim-sm;
                }

                &.previous {
                    transform: rotate(90deg) translateX(4px);
                    margin-right: @dim-sm;
                }
            }

        }
    }

    .@{atom}__table {
        border-collapse: collapse;
        border-spacing: inherit;
        font-size: @font-xs - 0.05em;

        a {
            text-decoration: none;
        }

        th, td {
            padding: 0;
            vertical-align: middle;
            height: 36px;

            &:not(:first-child) {
                text-align: left;
            }

            &.@{atom}__smCol {
                text-align: center;
                width: 30px !important;
                padding: 0 @dim-xs;
            }

            // Inside components, specific properties
            .Checkbox {
                & + span {
                    &:before {
                        margin-top: 3px;
                    }
                }
            }

            .IconButton {
                margin-right: 0;
                color: darken(@color-grey, 15%);

                &:hover,
                &:focus {
                    color: @color-primary;
                }
            }

            .Tag {
                display: inherit;
                font-size: inherit;
            }
        }

        thead {
            font-family: @font-primary;
            font-style: italic;
            color: @color-main-text;

            tr {
            }
            th {
                border-bottom: solid 1px @color-grey;
                font-weight: 400;
                .sorting {
                    visibility: hidden !important;
                }
            }

        }
        tbody {
            b {
                font-weight: 800;
                margin: auto 0;
                &[data-action]:hover {
                    cursor: pointer;
                    color: @color-active-input;
                }
            }

            tr {
                background-color: @color-transparent !important;
                border-bottom: solid 1px @color-grey;

                .@{atom}__deployBtn {
                    display: inline-block;
                    position: relative;
                    top: 2px;
                    @dim-deploy-size: 15px;
                    width: @dim-deploy-size;
                    height: @dim-deploy-size;
                    min-width: @dim-deploy-size;
                    margin: auto 0;
                    margin-left: 10px;
                    background: url(../../../public/ressources/img/expand_more_translation.svg) no-repeat center center;
                    background-size: 35px;
                    cursor: pointer;
                    transition: transform .2s ease-in-out;
                }

                &.shown {
                    .@{atom}__deployBtn {
                        transform: rotate(180deg);
                    }
                }

                &.selected {
                    background-color: @color-soft-white;
                }

                td {

                    &.sorting_1 {
                        background-color: @color-transparent !important;
                    }

                    &:first-child {
                        background-color: @color-transparent !important;
                        text-align: center;

                        .RI {
                            text-align: left;
                            font-size: 1.2em;
                        }

                        .Checkbox__ctn {
                            min-width: 30px;
                            display: inline-block;
                        }
                    }
                }
            }
        }
    }
}